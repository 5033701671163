(function(Controller, $) {

	Controller.init = function() {
		this.init.inited = true;
		this.media = null;
		this.scroll.current = 0;

		this.view = $(window);
		this.view.resize(this.resize.bind(this));
		this.view.scroll(this.scroll.bind(this));
		this.resize();
		this.scroll();

		this.boot(Controller.Header);
		this.boot(Controller.Footer);
		this.boot(Controller.Home);
		this.boot(Controller.Faq);
		this.boot(Controller.Search);
		this.boot(Controller.Share);
		this.boot(Controller.Guide);
		this.boot(Controller.Privacy);
		this.boot(Controller.Case);
		this.boot(Controller.Service);

		this.jump();
		//
		$('.smoothscroll').each((i, element) => {
			var trigger = $(element);
			var href = trigger.attr('href');

			if(href.match(/#\/(.+)/)) {
				var selector = RegExp.$1;
				var target = $('#' + selector);

				if(target.get(0)) {
					trigger.click((e) => {
						e.preventDefault();
						this.smoothscroll(target, null, trigger.data('offset'));
					})
				}
			}
		});
	}
	Controller.load = function() {
		this.load.loaded = true;
	}
	Controller.boot = function(object) {
		if(object.boot().get(0)) {
			object.main = this;
			object.init();
		}
	}
	Controller.resize = function() {
		if (window.matchMedia('(max-width:767px)').matches) {
			if(this.media != 'SP') {
				this.media = 'SP';
				this.view.trigger('resize:responsive');
				this.view.trigger('resize:responsive:SP');
			}
		} else {
			if (this.media != 'PC') {
				this.media = 'PC';
				this.view.trigger('resize:responsive');
				this.view.trigger('resize:responsive:PC');
			}
		}
	}
	Controller.scroll = function() {
		var current = this.view.scrollTop();

		if(this.scroll.current > current) {
			this.view.trigger('scroll:UP');
		}
		if(this.scroll.current < current) {
			this.view.trigger('scroll:DOWN');
		}
		this.scroll.current = this.view.scrollTop();
	}
	Controller.smoothscroll = function(target, duration, offset = 0) {
		var headerH = this.Header.view.height();

		if(target && target.get(0)) {
			var top = Math.min(target.offset().top - offset - headerH, $(document).height() - $(window).height());
		}
		else {
			var top = 0;
		}
		$('html,body').stop().animate({scrollTop : top}, duration);
	}
	Controller.scrollmagic = function(target, trigger, hook, options = {offset : 0, enter : true, leave : true}) {
		var scene = new ScrollMagic.Scene({
			triggerElement : $(trigger).get(0),
			triggerHook : hook,
			offset : options.offset
		});
		if(options.enter) {
			if(typeof options.enter != 'function') {
				options.enter = function(e) { $(target).addClass('magic'); }
			}
			scene.on('enter', options.enter);
		}
		if(options.leave) {
			if(typeof options.leave != 'function') {
				options.leave = function(e) { $(target).removeClass('magic'); }
			}
	   		scene.on('leave', options.leave);
		}
		if(!this.scrollmagic.controller) {
			this.scrollmagic.controller = new ScrollMagic.Controller();
		}
		this.scrollmagic.controller.addScene(scene);
	}
	Controller.accordion = function(e) {
		e.preventDefault();

		var button = $(e.currentTarget);
		var target = button.next();

		if(!button.hasClass('_open')) {
			button.addClass('_open');
			var h = target.height();
			var H = target.height('auto').height();
			target.height(h);
			target.stop().animate({height : H}, 300, () => {
				target.height('auto');
			})
		}
		else {
			button.removeClass('_open');
			target.stop().animate({height : 0}, 300, () => {
			});
		}
	}
	Controller.jump = function(e) {
		if(location.hash) {
			if(location.hash.match(/^#\/(.+)/)) {
				var target = $('#' + RegExp.$1);

				if(target.get(0)) {
					this.smoothscroll(target, 0, 0);
				}
			}
		}
	}


	Controller.Header = {
		boot : function() {
			return this.view = $('.cmn_global_header');
		},
		init : function() {
			this.view.navi = this.view.find('.navi');
			this.view.navi.items = this.view.navi.find('li');
			this.view.navi.items.mouseenter(this.megadrop.bind(this, true));
			this.view.navi.items.mouseleave(this.megadrop.bind(this, false));
			this.view.navi.items.children('a').click(this.accordion.bind(this));
			this.view.burger = this.view.find('.burger');
			this.view.burger.click(this.toggleNavigation.bind(this));

			if(this.main.view.on('resize:responsive:PC', () => {
				this.hideNavigation();
			}));
		},
		megadrop : function(show, e) {
			var trigger = $(e.currentTarget).children('a');
			var target  = trigger.next('.subnavi');

			if(target.get(0)) {
				e.preventDefault();

				if(show) {
					var h = target.height();
					var H = target.height('auto').height();
					target.height(h);
					target.stop().animate({height : H}, 250, () => {
						target.height('auto');
					});
				}
				else {
					target.stop().animate({height : 0}, 250, () => {
						target.height(0);
					});
				}
			}
		},
		accordion : function(e) {
			var trigger = $(e.currentTarget);
			var target = trigger.next('.subnavi').find('.submenu');

			if(target.get(0)) {
				if(this.main.media == 'SP') {
					e.preventDefault();
				}
				if(!trigger.hasClass('accordion_open')) {
					trigger.addClass('accordion_open');
					target.addClass('accordion_open');
					var h = target.height();
					var H = target.height('auto').height();
					target.height(h);
					target.stop().animate({height : H}, 250, () => {
						target.height('auto');
					});
				}
				else {
					trigger.removeClass('accordion_open');
					target.removeClass('accordion_open');
					target.stop().animate({height : 0}, 250, () => {
						target.height(0);
					});
				}
			}
		},

		toggleNavigation : function(e) {
			if(!this.view.burger.hasClass('open')) {
				this.showNavigation();
			}
			else {
				this.hideNavigation();
			}
		},
		showNavigation : function() {
			this.view.burger.addClass('open');
			this.view.navi.addClass('navi-open');
			bodyScrollLock.disableBodyScroll();
		},
		hideNavigation : function() {
			this.view.burger.removeClass('open');
			this.view.navi.removeClass('navi-open');
			bodyScrollLock.enableBodyScroll();
		}
	}



	Controller.Footer = {
		boot : function() {
			return this.view = $('.cmn_global_footer');
		},
		init : function() {
			this.view.pagetop = this.view.find('.pagetop');
			this.view.pagetop.click(this.scrolltop.bind(this));

			this.main.scrollmagic(this.view.pagetop, this.view.pagetop, 'onEnter', {
				enter : () => { this.view.pagetop.addClass('_stop'); },
				leave : () => { this.view.pagetop.removeClass('_stop'); },
			});
			this.main.scrollmagic(this.view.pagetop, $('main'), 'onLeave', {
				enter : () => { this.view.pagetop.addClass('_show'); },
				leave : () => { this.view.pagetop.removeClass('_show'); },
			});

			this.main.view.on('scroll:UP', () => {
				this.view.pagetop.addClass('_showshow');
			});
			this.main.view.on('scroll:DOWN', () => {
				this.view.pagetop.removeClass('_showshow');
			});
		},
		scrolltop : function() {
			this.main.smoothscroll(null, 300);
		}
	}



	Controller.Home = {
		boot : function() {
			return this.view = $('.contents_home');
		},
		init : function() {
			this.view.mainvisual = this.view.find('.mainvisual');
			this.view.mainvisual.title = this.view.mainvisual.find('.title');
			this.view.mainvisual.slide = this.view.mainvisual.find('.slide');
			this.view.mainvisual.title.addClass('show');
			this.view.mainvisual.slide.addClass('show');
			this.homeslide(0);
		},
		homeslide : function(duration) {
			var item = this.view.mainvisual.slide.find('.item').eq(0);
			item.appendTo(this.view.mainvisual.slide);

			item.css({opacity : 0});
			item.stop();
			item.animate({opacity : 1}, duration, 'linear', () => {
				setTimeout(this.homeslide.bind(this, 1500), 5000);
			});
			this.view.mainvisual.title.removeClass('white');
			this.view.mainvisual.title.removeClass('black');
			this.view.mainvisual.title.addClass(item.data('text-color'));
		}
	}


	Controller.Faq = {
		boot : function() {
			return this.view = $('.contents_faq');
		},
		init : function() {
			this.view.navi = this.view.find('.navi');
			this.view.navi.items = this.view.navi.find('li');
			this.view.list = this.view.find('.list');
			this.view.list.items = this.view.list.find('.item');
			this.view.list.items.titles = this.view.list.items.children('dt');
			this.view.list.items.titles.click(this.main.accordion);

			this.view.navi.items.click(this.naviClick.bind(this));
			// preview
			this.view.navi.items.filter('._default').click();
		},
		naviClick : function(e) {
			e.preventDefault();
			var button = $(e.currentTarget);
			var index = this.view.navi.items.index(button);
			var target = this.view.list.children().eq(index);
			this.main.smoothscroll(target, null, 10);
		}
	}


	Controller.Search = {
		boot : function() {
			return this.view = $('.contents_search');
		},
		init : function() {
			this.view.navi = this.view.find('.navi');
			this.view.navi.btn = this.view.navi.find('.btn');
			this.view.navi.map = this.view.navi.find('.map').find('object');
			this.view.list = this.view.find('.list');
			this.view.itemlist = this.view.list.find('.itemlist').children('dt');
			this.view.citynavi = this.view.list.find('.citynavi').find('li');

			if(this.main.load.loaded) {
				this.setup();
			}
			else {
				this.main.view.on('load', () => {
					this.setup();
				})
			}
			this.view.itemlist.click(this.main.accordion);
			this.view.citynavi.click(this.cityScroll.bind(this));
		},
		setup : function() {
			this.view.navi.btn.items = this.view.navi.btn.find('a');
			this.view.navi.map.areas = $(this.view.navi.map.get(0).contentDocument).find('svg').find('g');

			this.view.navi.btn.items.click((e) => {
				var target = $(e.currentTarget).data('target');
				this.areaChange(target);
			});
			this.view.navi.btn.items.mouseenter((e) => {
				var target = $(e.currentTarget).data('target');
				this.view.navi.map.areas.filter('.' + target).addClass('hover');
			});
			this.view.navi.btn.items.mouseleave((e) => {
				var target = $(e.currentTarget).data('target');
				this.view.navi.map.areas.filter('.' + target).removeClass('hover');
			});
			this.view.navi.map.areas.click((e) => {
				var target = $(e.currentTarget).attr('id');
				this.areaChange(target);
			});
			this.view.navi.map.areas.mouseenter((e) => {
				$(e.currentTarget).addClass('hover');
				var target = $(e.currentTarget).attr('id');
				this.view.navi.btn.items.filter('.' + target).addClass('hover');
			});
			this.view.navi.map.areas.mouseleave((e) => {
				$(e.currentTarget).removeClass('hover');
				var target = $(e.currentTarget).attr('id');
				this.view.navi.btn.items.filter('.' + target).removeClass('hover');
			});
			if(location.search) {
				var query = location.search.replace('?', '');

				if(query.match(/(area_[0-9]{2})/)) {
					this.areaChange(RegExp.$1, false);
				}
			}
			// プレビュー
			var defaultTarget = this.view.navi.btn.items.filter('._default');

			if(defaultTarget.get(0)) {
				this.areaChange(defaultTarget.data('target'), false);
				this.view.citynavi.filter('._default').click();
			}
		},
		areaChange : function(target, scroll=true) {
			this.view.navi.btn.items.removeClass('active');
			this.view.navi.map.areas.removeClass('active');
			this.view.navi.btn.items.filter('.' + target).addClass('active');
			this.view.navi.map.areas.filter('.' + target).addClass('active');

			this.view.list.hide();
			this.view.list.filter('#' + target).show();

			if(scroll) {
				if(this.scrolltimeout) {
					clearTimeout(this.scrolltimeout);
				}
				this.scrolltimeout = setTimeout(() => {
					this.main.smoothscroll(this.view.list.parent(), null, 0);
				}, 300);
			}
		},
		cityScroll : function(e) {
			e.preventDefault();
			var trigger = $(e.currentTarget);
			var parent = trigger.parent();
			var index = parent.children().index(trigger);
			var target = parent.parent().find('.itemlist').eq(index);

			this.main.smoothscroll(target);
		}
	}

	Controller.Guide = {
		boot : function() {
			return this.view = $('.contents_guide');
		},
		init : function() {
			this.view.list = this.view.find('.list');
			this.view.list.titles = this.view.list.find('.title');

			// preview
			var previewTarget = this.view.list.titles.filter('._default');

			if(previewTarget.get(0)) {
				this.main.smoothscroll(previewTarget, null, 10);
			}
		}
	}

	Controller.Privacy = {
		boot : function() {
			return this.view = $('.contents_privacy');
		},
		init : function() {
			this.view.list = this.view.find('.list');
			this.view.list.titles = this.view.list.find('.title');

			// preview
			var previewTarget = this.view.list.titles.filter('._default');

			if(previewTarget.get(0)) {
				this.main.smoothscroll(previewTarget, null, 10);
			}
		}
	}

	Controller.Share = {

		boot : function() {
			return this.view = $('.contents_share');
		},
		init : function() {
			this.view.navi = this.view.find('.share_navi');
			this.view.navi.items = this.view.navi.find('li');
			this.view.list = this.view.find('.share_list');
			this.view.list.items = this.view.list.find('.cmn_section');

			this.view.navi.items.click(this.scroll.bind(this));
			this.view.navi.items.filter('._default').click();
		},
		scroll : function(e) {
			e.preventDefault();

			var navi = $(e.currentTarget);
			var index = this.view.navi.items.index(navi);
			var list = this.view.list.items.eq(index);

			this.main.smoothscroll(list, null, 10);
		}
	}

	Controller.Case = {
		boot : function() {
			return this.view = $('.contents_case');
		},
		init : function() {
			this.view.triggers = this.view.find('.content_case_list').find('a');
			this.view.triggers.click(this.show.bind(this));

			this.view.modal = this.view.find('.content_case_modal');
			this.view.modal.items = this.view.modal.find('.item');
			this.view.modal.click((e) => {
				if(!$(e.target).parents('.dialog').get(0)) {
					this.hide();
				}
			});
			this.view.map.icons = this.view.find('.content_case_list').find('.map').find('a');
			this.iconBrink();

		},
		show : function(e) {
			var trigger = $(e.currentTarget);
			var targets = trigger.data('target').split(',');

			this.view.modal.items.hide();

			$.each(targets, (index, selector) => {
				this.view.modal.items.filter(selector).show();
			});
			this.view.modal.stop().fadeIn();
			bodyScrollLock.disableBodyScroll();
		},
		hide : function() {
			this.view.modal.stop().fadeOut();
			bodyScrollLock.enableBodyScroll();
		},
		iconBrink : function() {

			var index = Math.floor(Math.random() * this.view.map.icons.length);

			this.view.map.icons.removeClass('_brink');
			this.view.map.icons.eq(index).addClass('_brink');
			setTimeout(this.iconBrink.bind(this), 2000);
		}
	}

	Controller.Service = {
		boot : function() {
			return this.view = $('.contents_service');
		},
		init : function() {
			this.view.triggers = this.view.find('.feature_list').find('li');
			this.view.triggers.click(this.show.bind(this));

			this.view.modal = this.view.find('.content_service_modal');
			this.view.modal.items = this.view.modal.find('.item');
			this.view.modal.click((e) => {
				if(!$(e.target).parents('.dialog').get(0)) {
					this.hide();
				}
			});

		},
		show : function(e) {
			var trigger = $(e.currentTarget);
			var targets = trigger.data('target').split(',');

			this.view.modal.items.hide();

			$.each(targets, (index, selector) => {
				this.view.modal.items.filter(selector).show();
			});
			this.view.modal.stop().fadeIn();
			bodyScrollLock.disableBodyScroll();
		},
		hide : function() {
			this.view.modal.stop().fadeOut();
			bodyScrollLock.enableBodyScroll();
		},
	}

	$(window).on('load', () => {
		Controller.load();
	})
	Controller.init();

})({}, jQuery);
